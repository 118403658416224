import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import './App.css';
import DelayedReloadFallback from './components/ui/DelayedReloadFallback/DelayedReloadFallback';
import EmailVerificationBanner from './components/ui/EmailVerificationBanner/EmailVerificationBanner';
import Loader from './components/ui/Loader/Loader';
import NavigationBar from './components/ui/NavigationBar/NavigationBar';
import NavigationBarNotLogged from './components/ui/NavigationBarNotLogged/NavigationBarNotLogged';
import OfflineBanner from './components/ui/OfflineBanner/OfflineBanner';
import { ScrollToTop } from './components/ui/ScrollToTop/ScrollToTop';
import { ConfigContext } from './contexts/ConfigContext';
import { DomainContext } from './contexts/DomainContext';
import { ImageDomainContext } from './contexts/ImageDomainContext';
import { UserContext } from './contexts/UserContext';
import AdminGuard from './guards/AdminGuard';
import UserGuard from './guards/UserGuard';
import { useAutoUpdater } from './hooks/useAutoUpdater';
import useConfig from './hooks/useConfig';
import useUser from './hooks/useUser';

const AgesPageWithData = lazy(() => import('./pages/AgesPageWithData'));
const AuctionScrapingPageWithData = lazy(() => import('./pages/AuctionScrapingPageWithData'));
const AuctionScrapingDetailPageWithData = lazy(
  () => import('./pages/AuctionScrapingDetailPageWithData'),
);
const EditCoinPageWithData = lazy(() => import('./pages/EditCoinPageWithData'));
const EditAgePageWithData = lazy(() => import('./pages/EditAgePageWithData'));
const EditPlanPageWithData = lazy(() => import('./pages/EditPlanPageWithData'));
const EditRulerPageWithData = lazy(() => import('./pages/EditRulerPageWithData'));
const EditScrapingPlanPageWithData = lazy(() => import('./pages/EditScrapingPlanPageWithData'));
const EmailVerificationPageWithData = lazy(() => import('./pages/EmailVerificationPageWithData'));
const ForgotPasswordPageWithData = lazy(() => import('./pages/ForgotPasswordPageWithData'));
const IndexPageWithData = lazy(() => import('./pages/IndexPageWithData'));
const ListPageWithData = lazy(() => import('./pages/ListPageWithData'));
const LoginPageWithData = lazy(() => import('./pages/LoginPageWithData'));
const LogsPageWithData = lazy(() => import('./pages/LogsPageWithData'));
const ManageAccountPageWithData = lazy(() => import('./pages/ManageAccountPageWithData'));
const ManageAgesPageWithData = lazy(() => import('./pages/ManageAgesPageWithData'));
const ManageImagesPageWithData = lazy(() => import('./pages/ManageImagesPageWithData'));
const ManagePlansPageWithData = lazy(() => import('./pages/ManagePlansPageWithData'));
const ManageUsersPageWithData = lazy(() => import('./pages/ManageUsersPageWithData'));
const ManageSubscriptionsPageWithData = lazy(
  () => import('./pages/ManageSubscriptionsPageWithData'),
);
const NotFoundPage = lazy(() => import('./components/pages/NotFoundPage'));
const OverviewPageWithData = lazy(() => import('./pages/OverviewPageWithData'));
const PrivacyPolicyPageWithData = lazy(() => import('./pages/PrivacyPolicyPageWithData'));
const RegistrationPageWithData = lazy(() => import('./pages/RegistrationPageWithData'));
const ResetPasswordPageWithData = lazy(() => import('./pages/ResetPasswordPageWithData'));
const SearchPageWithData = lazy(() => import('./pages/SearchPageWithData'));
const SubscriptionPageWithData = lazy(() => import('./pages/SubscriptionPageWithData'));
const TermsOfUsePageWithData = lazy(() => import('./pages/TermsOfUsePageWithData'));

function App() {
  const domainElement: HTMLInputElement | null = document.getElementById(
    'domain',
  ) as HTMLInputElement;
  let domain = '';
  domain = domainElement.value;

  const imageDomainElement: HTMLInputElement | null = document.getElementById(
    'imageDomain',
  ) as HTMLInputElement;
  let imageDomain = '';
  imageDomain = imageDomainElement.value;

  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { data: userData, isLoading: isUserLoading } = useUser(domain);
  const user = userData?.data ?? null;

  const { data: configData, isLoading: isConfigLoading } = useConfig(domain);
  const config = configData?.data ?? null;

  useAutoUpdater({ domain });

  if (!domainElement) {
    return <div>Domain is not set</div>;
  }
  if (!imageDomainElement) {
    return <div>Image domain is not set</div>;
  }

  const getIndexPage = () => {
    if (isUserLoading || isConfigLoading) {
      return <Loader />;
    }

    if (!user) {
      return <IndexPageWithData />;
    }

    return <Navigate to="/search" replace />;
  };

  const showBackground =
    location.pathname === '/' &&
    !searchParams.get('q') &&
    !user &&
    !isUserLoading &&
    !isConfigLoading;

  return (
    <DomainContext.Provider value={domain}>
      <ImageDomainContext.Provider value={imageDomain}>
        <UserContext.Provider value={{ user, isLoading: isUserLoading }}>
          <ConfigContext.Provider value={config}>
            <div
              className={`${showBackground ? 'background' : ''} mx-auto w-full max-w-screen-3xl`}
            >
              <div className="content">
                <OfflineBanner />
                <EmailVerificationBanner isDisplayed={(user && !user.email_verified_at) || false} />
                <div className="mx-auto w-[90%] max-w-8xl px-2 sm:w-full sm:px-4">
                  {user ? (
                    <NavigationBar user={user} />
                  ) : (
                    <NavigationBarNotLogged
                      isRegistrationActive={config?.ENABLE_REGISTRATION || false}
                      isBackgroundActive={showBackground}
                    />
                  )}
                  <ScrollToTop />
                  <Suspense fallback={<DelayedReloadFallback timeout={10000} />}>
                    <Routes>
                      <Route path="*" element={<NotFoundPage />} />
                      <Route path="/" element={getIndexPage()} />
                      <Route path="/search" element={<SearchPageWithData />} />
                      <Route
                        path="/login"
                        element={
                          <LoginPageWithData
                            user={user}
                            isLoading={isUserLoading || isConfigLoading}
                          />
                        }
                      />
                      <Route path="/overview" element={<OverviewPageWithData />} />
                      <Route
                        path="/forgot-password"
                        element={
                          <ForgotPasswordPageWithData
                            user={user}
                            isLoading={isUserLoading || isConfigLoading}
                          />
                        }
                      />
                      <Route
                        path="/reset-password/:token"
                        element={
                          <ResetPasswordPageWithData
                            user={user}
                            isLoading={isUserLoading || isConfigLoading}
                          />
                        }
                      />
                      <Route path="/manage-account" element={<ManageAccountPageWithData />} />
                      {config?.ENABLE_REGISTRATION && (
                        <Route
                          path="/register"
                          element={<RegistrationPageWithData user={user} />}
                        />
                      )}
                      <Route
                        path="/verify/:userId/:token"
                        element={<EmailVerificationPageWithData />}
                      />
                      <Route path="/terms-of-use" element={<TermsOfUsePageWithData />} />
                      <Route path="/privacy-policy" element={<PrivacyPolicyPageWithData />} />

                      <Route element={<UserGuard domain={domain} />}>
                        <Route path="/age/:ageId" element={<AgesPageWithData />} />
                        <Route
                          path="/ruler/:rulerId"
                          element={<ListPageWithData order="year:asc,name:asc,sold_at:desc" />}
                        />
                        <Route path="/ages" element={<AgesPageWithData />} />
                        <Route path="/subscription" element={<SubscriptionPageWithData />} />
                      </Route>

                      <Route element={<AdminGuard />}>
                        <Route path="/auction-scraping" element={<AuctionScrapingPageWithData />} />
                        <Route
                          path="/auction-scraping/:scrapingPlanId"
                          element={<AuctionScrapingDetailPageWithData />}
                        />
                        <Route
                          path="/auction-scraping/add"
                          element={<EditScrapingPlanPageWithData />}
                        />
                        <Route
                          path="/auction-scraping/edit/:scrapingPlanId"
                          element={<EditScrapingPlanPageWithData />}
                        />
                        <Route path="/age/add" element={<EditAgePageWithData />} />
                        <Route path="/age/add/:parentId" element={<EditAgePageWithData />} />
                        <Route path="/age/edit/:ageId" element={<EditAgePageWithData />} />
                        <Route path="/coin/add" element={<EditCoinPageWithData />} />
                        <Route path="/coin/edit/:coinId" element={<EditCoinPageWithData />} />
                        <Route path="/coin/image/:coinId" element={<ManageImagesPageWithData />} />
                        <Route
                          path="/coin/last"
                          element={<ListPageWithData order="created_at:desc" />}
                        />
                        <Route path="/manage-age" element={<ManageAgesPageWithData />} />
                        <Route path="/manage-age/:ageId" element={<ManageAgesPageWithData />} />
                        <Route path="/manage-plans" element={<ManagePlansPageWithData />} />
                        <Route
                          path="/manage-users"
                          element={<ManageUsersPageWithData user={user} />}
                        />
                        <Route
                          path="/manage-subscriptions/:userId"
                          element={<ManageSubscriptionsPageWithData />}
                        />
                        <Route path="/plan/add" element={<EditPlanPageWithData />} />
                        <Route path="/plan/edit/:planId" element={<EditPlanPageWithData />} />
                        <Route path="/ruler/add/:ageId" element={<EditRulerPageWithData />} />
                        <Route path="/ruler/edit/:rulerId" element={<EditRulerPageWithData />} />
                        <Route path="/logs" element={<LogsPageWithData />} />
                      </Route>
                    </Routes>
                  </Suspense>
                </div>
              </div>
            </div>
          </ConfigContext.Provider>
        </UserContext.Provider>
      </ImageDomainContext.Provider>
    </DomainContext.Provider>
  );
}

export default App;
