import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import VerifyEmailGuardPage from '../components/pages/VerifyEmailGuardPage';
import Alert from '../components/ui/Alert/Alert';
import Loader from '../components/ui/Loader/Loader';
import { UserContext } from '../contexts/UserContext';
import { useSendVerificationEmail } from '../hooks/useSendVerificationEmail';
import { SendVerificationEmailResponse } from '../types/API/SendVerificationEmailResponse';

interface UserGuardProps {
  domain: string;
}

function UserGuard({ domain }: UserGuardProps) {
  const { user, isLoading } = useContext(UserContext);
  const sendVerificationEmail = useSendVerificationEmail(domain);

  if (isLoading) {
    return <Loader />;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  const handleSendVerificationEmail = async (): Promise<SendVerificationEmailResponse> => {
    const result = await sendVerificationEmail.mutateAsync();
    return {
      success: result.success,
      message: result.message,
    };
  };

  if (!user.email_verified_at) {
    return (
      <>
        {sendVerificationEmail.isSuccess && sendVerificationEmail.data.success && (
          <Alert type="success" text={sendVerificationEmail.data.message} />
        )}
        {sendVerificationEmail.isSuccess && !sendVerificationEmail.data.success && (
          <Alert type="error" text={sendVerificationEmail.data.message} />
        )}
        <VerifyEmailGuardPage user={user} onSendVerificationEmail={handleSendVerificationEmail} />
      </>
    );
  }

  return <Outlet />;
}

export default UserGuard;
