import { FC, Fragment } from 'react';
import {
  faBars,
  faCrown,
  faSearch,
  faCoins,
  faPlusCircle,
  faSquareXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react';
import { User } from '../../../types/User';
import { NavigationLinkMobile } from './NavigationLinkMobile';

interface NavigationMenuProps {
  user: User | null;
}

export const NavigationMenu: FC<NavigationMenuProps> = ({ user }) => (
  <div className="inline-block lg:hidden">
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="ml-6">
        {({ open }) => <FontAwesomeIcon icon={open ? faSquareXmark : faBars} />}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          static
        >
          <div className="p-1">
            <NavigationLinkMobile to="/search" icon={faSearch} title="Vyhledávání" />
            <NavigationLinkMobile to="/ages" icon={faCrown} title="Katalog období" />
          </div>
          {user && user.role === 'admin' && (
            <div className="p-1">
              <NavigationLinkMobile to="/coin/last" icon={faCoins} title="Poslední přidané" />
              <NavigationLinkMobile
                to="/coin/add"
                icon={faPlusCircle}
                title="Přidat prodej mince"
              />
            </div>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  </div>
);
