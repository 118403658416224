import { FC } from 'react';
import {
  faUserCircle,
  faCoins,
  faCrown,
  faPlusCircle,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { User } from '../../../types/User';
import NavigationButtonLink from './NavigationButtonLink';
import { NavigationLink } from './NavigationLink';
import { NavigationMenu } from './NavigationMenu';

interface NavigationProps {
  user: User;
}

const NavigationBar: FC<NavigationProps> = ({ user }) => {
  const isSubscriptionActive = user.role === 'admin' || user.isSubscriptionActive;

  return (
    <nav className="mb-1 flex w-full items-center py-2">
      <NavLink to="/search" title="Groše - hlavní strana">
        <img className="mb-2 w-16 sm:w-20" src="https://www.grose.cz/grose.png" alt="Groše" />
      </NavLink>
      <div className="hidden lg:inline-block">
        <NavigationLink to="/search" title="Vyhledávání" icon={faSearch} />
        <NavigationLink to="/ages" title="Katalog období" icon={faCrown} />
        {user && user.role === 'admin' && (
          <>
            <NavigationLink to="/coin/last" title="Poslední přidané" icon={faCoins} />
            <NavigationLink to="/coin/add" title="Přidat prodej mince" icon={faPlusCircle} />
          </>
        )}
      </div>
      <div className="ml-auto flex items-center">
        <NavigationButtonLink
          color={isSubscriptionActive ? 'gradient-purple-blue' : 'blue'}
          to="/overview"
          title={`${user.name} ${user.surname}`}
          icon={faUserCircle}
        />
        <NavigationMenu user={user} />
      </div>
    </nav>
  );
};

export default NavigationBar;
