import { useState, useEffect } from 'react';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import g from '../../../assets/g.svg';
import Button, { ButtonColors } from '../../elements/Button/Button';
import Loader from '../Loader/Loader';

const DelayedReloadFallback = ({ timeout = 10000 }) => {
  const [showReloadButton, setShowReloadButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowReloadButton(true);
    }, timeout);

    return () => clearTimeout(timer);
  }, [timeout]);

  if (!showReloadButton) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col items-center justify-center pt-[25vh]">
      <img src={g} alt="" className="size-32 animate-pulse" />
      <h3 className="mb-4 text-2xl font-bold sm:text-3xl">Stále probíhá načítání...</h3>
      <p className="mb-8 text-lg">
        Prosím o chvíli strpení. Pokud se stránka stále nenačítá, zkuste ji obnovit.
      </p>

      <div className="mt-6">
        <Button
          color={ButtonColors.Blue}
          text="Obnovit stránku"
          icon={faRotateRight}
          showIconMobile
          onClick={() => window.location.reload()}
        />
      </div>
    </div>
  );
};

export default DelayedReloadFallback;
